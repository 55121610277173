.work-container {
  height: 140vh;
  width: 100vw;
  background-color: #0f0f0f;
  color: #f3f3f3;
}
.work-top {
  height: 25vh;
  padding: 120px;
  padding-top: 90px;
  padding-bottom: 0px;
}
.gallery-wrapper {
  height: auto;
  padding-left: 120px;
  padding-top: 0px;
  padding-right: 130px;
}
.w-sub-title {
  font-weight: 400;
  font-size: 18px;
}
.w-main-title {
  font-size: 84px;
  font-weight: 500;
  line-height: 100px;
  width: 100%;
  margin: 0;
}
.process-content {
  color: #f3f3f3;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  flex: 1;
  padding-top: 30px;
  position: relative;
}
.steps {
  display: flex;
  flex-direction: column;
  width: 15%;
}
.work-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.work-wrapper img {
  object-fit: cover;
  object-position: 0px 0px;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
}
.work-wrapper img:hover {
  opacity: 0.6;
}
.content {
  height: 100%;
  width: 85%;
  flex: 2;
}
.TabContent {
  width: 100%;
  height: 100%;
  background-color: #0f0f0f;
  display: flex;
}
.interium {
  font-size: 14px;
  letter-spacing: 2px;
  color: #383838;
}
#step {
  list-style: none;
  font-size: 14px;
  letter-spacing: 2px;
  margin: 0;
  font-weight: 400;
  padding-top: 2px;
  padding-bottom: 2px;
  color: #666666;
  transition: all 0.1s ease-in-out;
}
#step:hover:not(.active) {
  cursor: pointer;
  color: #f3f3f377;
}
.active {
  font-weight: 500;
}
.content {
  padding-left: 60px;
  display: flex;
  flex-direction: column;
  width: 40%;
}
.content-txt {
  flex-wrap: wrap;
  font-size: 20px;
  letter-spacing: 1px;
}
@media only screen and (max-height: 800px) {
  .w-main-title {
    font-size: 64px;
  }
  .work-container {
    height: 160vh;
  }
}

@media only screen and (max-width: 1200px) {
  .w-main-title {
    font-size: 64px;
  }
}

@media only screen and (max-width: 1200px) {
  .w-main-title {
    font-size: 52px;
  }
  .w-sub-title {
    font-size: 14px;
  }
}
@media only screen and (max-width: 900px) {
  .w-main-title {
    text-align: center !important;
    width: 100%;
  }

  .process-content {
    flex-direction: row;
    padding-top: 0;
  }

  .content {
    padding: 0;
    padding-top: 30px;
  }

  .work-wrapper {
    height: 100%;
    width: 100%;
  }

  .work-wrapper img {
    height: 300px;
  }

  .steps {
    margin: 0 auto;
    width: 30%;
  }

  .w-sub-title {
    text-align: left !important;
  }

  .gallery-wrapper {
    height: auto;
    padding: 0 60px;
  }

  .work-container {
    height: auto;

    padding-bottom: 90px;
  }

  .date {
    text-align: center !important;
  }
}

@media only screen and (max-width: 780px) {
  .w-main-title {
    font-size: 40px;
    line-height: 50px !important;
  }
  .work-top {
    padding: 45px;
  }
}

@media only screen and (max-width: 640px) {
  .gallery-wrapper {
    padding: 0 10px;
  }

  .process-content {
    flex-direction: column;
  }

  .content {
    margin: 0 auto;
    width: 100%;
  }

  .work-wrapper img {
    height: 100%;
    width: 100%;
  }

  .steps {
    text-align: center;
    margin: 0px;
    width: 100%;
  }

  .w-sub-title {
    text-align: center !important;
  }
  .w-main-title {
    font-size: 30px !important;
  }
}
