:root {
  --scrollbar-height: "0px";
}

.nav-wrapper {
  position: fixed;
  z-index: 50;
}

.left-side {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.left-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100px;
  padding: 30px;
}

.bottom-text {
  transform: rotate(-90deg);
  font-weight: 300;
  letter-spacing: 2px;
  padding-left: 80px;
}

.right-side {
  display: flex;
  flex-direction: column;
  position: fixed;
  height: 100%;
  right: 0;
  top: 0;
}

.right-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 45px;
  align-items: center;
  border-left: 1px solid #303030;
  background-color: none;
  mix-blend-mode: difference;
  position: relative;
}

.right-wrapper::before {
  content: "";
  position: absolute;
  width: 1px;
  background-color: rgb(146, 146, 146);
  height: var(--scrollbar-height);
  left: 0;
  top: 0;
}
.hamburger-container {
  display: flex;
  flex-direction: column;
  width: 40px;
  height: 22px;
  justify-content: space-between;
  flex: 1;
}

.hamburger {
  display: flex;
  flex-direction: column;
  width: 40px;
  height: 22px;
  justify-content: space-between;
}

.hamburger:hover {
  cursor: pointer;
}

.hamburger:hover .line {
  transform: rotate(-15deg);
}

.line {
  height: 1px;
  width: 40px;
  background-color: #f3f3f3;
  transition: transform 0.2s;
}
.social-links {
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.social-link {
  font-weight: 300;
  font-size: 18px;
}

.close {
  background-color: transparent;
  border: none;
  color: #f3f3f3;
  position: absolute;
  font-size: 22px;
  top: 10px;
  right: 20px;
  letter-spacing: 5px;
  font-weight: 300;
  padding: 10px;
  width: auto;
  text-align: left;
  margin-top: 20px;
  transition: all 0.3s ease-in-out;
  font-size: 24px;
}

.close:hover {
  cursor: pointer;
  cursor: pointer;
  letter-spacing: 7px;
}

nav {
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  max-height: 100dvh;
  background-color: #0f0f0f;
  transition: left 0.3s ease-in-out;
}

.container-nav {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100dvh;

  margin-left: 10px;
}

.container-nav svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.container-nav svg g path {
  stroke-dasharray: 200;
  stroke-dashoffset: 900;
  animation: animatePath 12s ease-in-out infinite alternate;
  animation-delay: calc(0.05s * (1 + (var(--index))));
}

.container-nav svg g path:nth-child(25) {
  --index: 0;
}

.container-nav svg g path:nth-child(24) {
  --index: 1;
}

.container-nav svg g path:nth-child(23) {
  --index: 2;
}

g path:nth-child(22) {
  --index: 3;
}

g path:nth-child(21) {
  --index: 4;
}

g path:nth-child(20) {
  --index: 5;
}

g path:nth-child(19) {
  --index: 6;
}

g path:nth-child(18) {
  --index: 7;
}

g path:nth-child(17) {
  --index: 8;
}

g path:nth-child(16) {
  --index: 9;
}

g path:nth-child(15) {
  --index: 10;
}

g path:nth-child(14) {
  --index: 11;
}

g path:nth-child(13) {
  --index: 12;
}

g path:nth-child(12) {
  --index: 13;
}

g path:nth-child(11) {
  --index: 14;
}

g path:nth-child(10) {
  --index: 15;
}

g path:nth-child(9) {
  --index: 16;
}

g path:nth-child(8) {
  --index: 17;
}

g path:nth-child(7) {
  --index: 18;
}

g path:nth-child(6) {
  --index: 19;
}

g path:nth-child(5) {
  --index: 20;
}

g path:nth-child(4) {
  --index: 21;
}

g path:nth-child(3) {
  --index: 22;
}

g path:nth-child(2) {
  --index: 23;
}

g path:nth-child(1) {
  --index: 24;
}

@keyframes animatePath {
  0% {
    stroke-dashoffset: 1000;
    opacity: 0.4;
  }
  50% {
    stroke-dashoffset: 0;
    opacity: 0.4;
  }
  100% {
    stroke-dashoffset: 1000;
    opacity: 0.4;
  }
}

.nav-items a {
  flex: 1;
  display: flex;
  flex-direction: row;
  color: white;
  text-decoration: none;
  font-size: 84px;
  margin: 10px 0px;
  text-transform: uppercase;
}

.nav-items a:hover {
  background-color: rgba(27, 27, 27, 0.3);
  backdrop-filter: blur(5px);
}

nav.open {
  left: 0;
}

@media only screen and (max-width: 1100px) {
  .right-side,
  .right-wrapper {
    border: none;
  }
  .nav-items a {
    font-size: 72px;
  }

  .close {
    font-size: 22px;
  }
}

@media only screen and (max-width: 900px) {
  .right-wrapper::before {
    height: 0 !important;
  }
}

@media only screen and (max-width: 760px) {
  .right-wrapper {
    border: none;
  }
  .nav-items a {
    font-size: 72px;
  }
  .close {
    font-size: 18px;
  }
}

@media only screen and (max-width: 640px) {
  .nav-wrapper {
    backdrop-filter: blur(10px);
    width: 100vw;
    /*mix-blend-mode: difference;*/
  }
  .right-wrapper {
    border: none;
  }
  .nav-items a {
    font-size: 48px;
  }
  .close {
    font-size: 16px;
  }
}

.link-svg {
  transition: stroke 0.3s ease;
  stroke-width: 1px;
  stroke: #fefefe;
}

.home-hovered {
  stroke: rgb(32, 32, 32);
}

.about-hovered {
  stroke: rgb(68, 68, 68);
}

.work-hovered {
  stroke: rgb(122, 122, 122);
}

.contact-hovered {
  stroke: rgb(235, 235, 235);
}
