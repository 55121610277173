.contact-container {
  background-color: #fff;
  color: #0f0f0f;
  height: 82vh;
  padding-right: 130px;
  padding-top: 30px;
  position: relative;
  z-index: 10;
}

.contact-body {
  display: flex;
  width: 100%;
  padding: 120px;
}
.space {
  flex: 1;
}
.email {
  font-size: 36px;
  text-decoration: underline;
  z-index: 100;
  position: relative;
}
.email:hover {
  transition: all 0.1s ease-in-out;
  cursor: pointer;
  opacity: 0.3 !important;
}
.c-special {
  font-size: 16px;
  padding-bottom: 20px;
}
.social {
  position: relative;
  z-index: 100;
}
.social:hover {
  transition: all 0.1s ease-in-out;
  opacity: 0.6 !important;
  cursor: pointer;
}
.contact-desc {
  padding-top: 30px;
}
.italics {
  font-style: italic;
  font-weight: 300;
  text-decoration: underline;
}

@media only screen and (max-width: 1500px) {
  .w-main-title {
    font-size: 72px;
  }
  .contact-desc {
    padding-top: 15px;
  }
}
@media only screen and (max-width: 1300px) {
  .w-main-title {
    font-size: 64px;
  }
  .contact-desc {
    padding-top: 10px;
  }
  .c-special {
    font-size: 12px;
  }
  .email {
    font-size: 28px;
  }
  .social {
    font-size: 14px;
  }
}
@media only screen and (max-height: 800px) {
  .contact-desc {
    padding-top: 0px;
    font-size: 14px;
  }
  .c-special {
    font-size: 12px;
  }
}

@media only screen and (max-width: 1200px) {
  .work-top,
  .contact-body {
    padding-left: 90px;
  }
  .w-main-title {
    font-size: 48px;
    line-height: 65px;
  }
  .contact-desc {
    font-size: 14px;
  }
  .w-sub-title {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1100px) {
  .contact-container {
    padding-right: 0px;
  }
  .work-top,
  .contact-body {
    padding-left: 90px;
    padding-right: 140px;
  }
  .w-main-title {
    font-size: 48px;
    line-height: 65px;
  }
  .contact-desc {
    font-size: 14px;
  }
  .w-sub-title {
    font-size: 14px;
  }
}

@media only screen and (max-width: 900px) {
  .contact-container {
    padding-right: 0px;
  }
  .work-top,
  .contact-body {
    padding-left: 90px;
    padding-right: 90px;
  }
  #main-title {
    font-size: 48px;
    line-height: 65px;
    text-align: center;
    margin-right: 0px;
    width: 100%;
  }
  .contact-desc {
    font-size: 14px;
    text-align: center;
  }
  .w-sub-title {
    font-size: 14px;
    text-align: center;
  }
}

@media only screen and (max-width: 900px) {
  .contact-body {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
  }
  .c-special {
    text-align: center;
  }
  .email {
    padding-bottom: 60px;
  }
  .social {
    text-align: center;
    width: 100%;
    padding: 5px;
  }
  .c-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  #main-title {
    font-size: 36px;
  }
  .contact-desc {
    font-size: 12px;
  }
  .c-special {
    font-size: 10px;
    text-align: center;
  }
  .work-top {
    padding-left: 30px;
    padding-right: 30px;
  }
  .contact-desc {
    margin-bottom: 20px;
  }
  .contact-body {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 20px;
    padding-top: 0px;
    margin-top: 40px;
  }
  .w-main-title {
    font-size: 30px !important;
  }
}
