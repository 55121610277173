.about-container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  color: #0f0f0f;
}
.about-text-container {
  display: flex;
  flex-direction: column;
  padding-top: 100px;
  padding-left: 120px;
  flex: 1;
}
.about-top-text {
  font-size: 20px;
}
.about-main-text {
  font-size: 80px;
  font-weight: 500;
  width: 68%;
  line-height: 96px;
}
.bottom-text-container {
  padding-left: 120px;
  padding-bottom: 100px;
  width: 100%;
  display: flex;
}
.accent-text {
  letter-spacing: 5px;
  font-weight: 300;
  font-size: 12px;
  margin-bottom: 60px;
}
.bottom-txt {
  font-size: 16px;
  width: 30%;
}
.text-wrapper {
  flex: 1;
}
.button-wrapper {
  display: flex;
  align-items: flex-end;
}
.projects-btn {
  background: none;
  border: none;
  color: #0f0f0f;
  letter-spacing: 5px;
  font-weight: 300;
  height: 20px;
  text-align: right;
  padding-right: 200px;
  transition: all 0.2s ease-in-out;
  position: relative;
}
.projects-btn:hover {
  cursor: pointer;
  opacity: 0.6;
}

@media only screen and (max-width: 1700px) {
  .about-main-text {
    font-size: 70px;
    line-height: 82px;
  }
}

@media only screen and (max-width: 1450px) {
  .about-main-text {
    font-size: 60px;
    line-height: 72px;
  }
  .bottom-txt {
    width: 50%;
  }
  .about-top-text {
    font-size: 16px;
  }
  .about-text-container {
    padding-top: 150px;
  }
}
@media only screen and (max-height: 800px) {
  .about-main-text {
    font-size: 54px;
    line-height: 70px;
    padding-bottom: 10px;
  }
  .about-top-text {
    font-size: 16px;
  }
  .bottom-txt {
    width: 45%;
  }
}

@media only screen and (max-width: 1250px) {
  .about-main-text {
    font-size: 50px;
    line-height: 62px;
  }
  .bottom-txt {
    width: 70%;
  }
  .projects-btn {
    font-size: 12px;
  }
}

@media only screen and (max-width: 1100px) {
  .about-main-text {
    font-size: 42px;
    line-height: 52px;
  }
  .bottom-txt {
    width: 70%;
  }
  .projects-btn {
    font-size: 11px;
    padding-right: 120px;
  }
}

@media only screen and (max-width: 900px) {
  .about-main-text {
    font-size: 36px;
    line-height: 48px;
    text-align: center;
    margin-right: 0px;
    width: 100%;
    padding: 30px;
  }
  .bottom-txt {
    width: 100%;
    text-align: center;
    padding: 60px;
    font-size: 14px;
  }
  .accent-text {
    margin-bottom: 30px;
    text-align: center;
  }
  .about-text-container {
    padding-left: 0px;
    align-items: center;
  }
  .about-top-text {
    text-align: center;
  }
  .projects-btn {
    font-size: 11px;
    padding-right: 0px;
  }
  .bottom-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 0px;
  }
}

@media only screen and (max-width: 500px) {
  .about-main-text {
    font-size: 28px;
    line-height: 42px;
    padding-top: 15px;
  }
  .about-top-text {
    font-size: 12px;
  }
  .accent-text {
    margin-bottom: 0;
  }
  .bottom-txt {
    padding: 30px;
  }
}
@media only screen and (max-height: 900px) {
  .about-main-text {
    font-size: 48px;
    line-height: 60px;
  }
  .bottom-txt {
    font-size: 14px;
  }
}

@media only screen and (max-width: 400px) {
  .about-main-text {
    font-size: 24px;
    line-height: 32px;
    padding-top: 15px;
  }
}
