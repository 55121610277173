.benefits-container {
  display: flex;
  align-items: center;
  min-height: 170vh;
  flex-direction: column;
  color: #0f0f0f;
  padding-top: 200px;
  padding-left: 120px;
  margin-bottom: 180px;
}
.benefit-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.num {
  letter-spacing: 5px;
  font-weight: 300;
  font-size: 12px;
}
.benefit-title {
  font-size: 84px;
  font-weight: 500;
  line-height: 100px;
}
.benefit-desc {
  width: 50%;
  padding-top: 30px;
}

.b2,
.b3 {
  margin-top: 400px;
}

@media only screen and (max-width: 1500px) {
  .benefit-title {
    font-size: 64px;
    line-height: 80px;
  }
  .benefit-desc {
    font-size: 14px;
  }
}
@media only screen and (max-height: 800px) {
  .benefit-title {
    font-size: 64px;
  }
  .benefit-desc {
    font-size: 14px;
    width: 50%;
  }
}
@media only screen and (max-width: 1200px) {
  .benefit-title {
    font-size: 48px;
    line-height: 80px;
  }
  .benefit-desc {
    font-size: 14px;
    padding-top: 15px;
  }
}

@media only screen and (max-width: 900px) {
  .benefit-title {
    text-align: center;
    padding-right: 0px;
    width: 100%;
  }
  .benefit-desc {
    font-size: 14px;
    padding-top: 15px;
    text-align: center;
    width: 100%;
    padding-left: 120px;
    padding-right: 120px;
  }
  .benefits-container {
    padding-left: 0px;
  }
  .b-num {
    text-align: center;
  }
}

@media only screen and (max-width: 800px) {
  .benefit-title {
    font-size: 36px;
    text-align: center;
    padding-right: 0px;
    width: 100%;
  }
  .benefit-desc {
    font-size: 13px;
    padding-top: 0px;
  }
}

@media only screen and (max-width: 550px) {
  .benefit-title {
    font-size: 28px;
    text-align: center;
    padding-right: 0px;
    width: 100%;
  }
  .benefit-desc {
    font-size: 13px;
    padding-top: 0px;
    padding-left: 60px;
    padding-right: 60px;
  }
  .b2,
  .b3 {
    margin-top: 300px;
  }
  .benefits-container {
    margin-bottom: 90px;
  }
}

@media only screen and (max-width: 450px) {
  .benefit-title {
    font-size: 24px;
    text-align: center;
    padding-right: 0px;
    width: 100%;
    padding: 30px;
    line-height: 40px;
  }
  .benefit-desc {
    font-size: 12px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .b2,
  .b3 {
    margin-top: 300px;
  }
  .benefits-container {
    margin-bottom: 90px;
  }
}
