.proj-title,
.proj-num {
  font-size: 11px;
  font-weight: 300;
  letter-spacing: 3px;
}
.proj-title {
  margin-bottom: 120px;
}
.projects-wrapper {
  width: 100%;
  height: auto;
  margin-top: 90px;
}
.projects-wrapper > :not(:first-child) {
  margin-top: 150px;
}
.project {
  display: flex;
  align-items: center;
  height: auto;
  opacity: 0.6;
  transition: all 0.12s ease-in-out;
}
.project-img {
  position: absolute;
  opacity: 0;
  right: 40%;
  transition: all 0.12s ease-in-out;
}
.hyrewise {
  right: 57%;
}
.proj-txt-wrapper {
  display: flex;
  flex-direction: column;
}
.project:hover {
  opacity: 1;
  cursor: pointer;
}
.project:hover > .project-img {
  opacity: 0.7;
}

.proj-name {
  font-size: 64px;
  font-weight: 400;
  position: relative;
  z-index: 20;
}
.proj-date {
  font-size: 12px;
  font-weight: 300;
}

@media only screen and (max-height: 800px) {
  .proj-wrapper {
    margin-top: 60px;
  }
}

@media only screen and (max-width: 1200px) {
  .proj-name {
    font-size: 52px;
  }
}

@media only screen and (max-width: 1000px) {
  .proj-name {
    font-size: 42px;
  }
}

@media only screen and (max-width: 900px) {
  .proj-title {
    text-align: center;
  }
  .project-img {
    height: 20%;
  }
}

@media only screen and (max-width: 641px) {
  .proj-name {
    font-size: 32px;
  }
  .projects-wrapper {
    padding: 30px;
  }
  .project-img {
    right: 20%;
  }
}

@media only screen and (max-width: 520px) {
  .proj-name {
    font-size: 28px;
  }
  .project-img {
    right: 5%;
  }
}

@media only screen and (max-width: 450px) {
  .proj-name {
    font-size: 20px;
  }
  .project-img {
    height: 15%;
  }
}
