.home-container {
  min-height: 100vh;
  color: #f3f3f3;
  display: flex;
  flex-direction: column;
  background-color: #0f0f0f;
  z-index: 10;
  position: relative;
}
.main-text-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 175px;
  margin-left: 150px;
  position: relative;
}
.top-text {
  font-size: 12px;
  letter-spacing: 5px;
  font-weight: 300;
}
.main-title {
  font-size: 112px;
  font-weight: 400;
  line-height: 130px;
}

.thin {
  font-weight: 100;
  transition: all 0.1s ease-in-out;
}
.main-title:hover > .thin {
  font-weight: 400;
  color: #212121;
  -webkit-text-stroke: 1px #ffffff;
  -webkit-text-fill-color: transparent;
}

.contact-btn {
  background: none;
  border: none;
  color: #f3f3f3;
  width: 150px;
  height: 50px;
  border-radius: 30px;
  border: 1px solid #fff;
  font-weight: 300;
  font-size: 14px;
  padding: 10px;
  width: 175px;
  text-align: center;
  margin-top: 60px;
  transition: all 0.12s ease-in-out;
}
.contact-btn:hover {
  cursor: pointer;
  background-color: #fff;
  color: #0f0f0f;
  font-weight: 500;
}
.scroll-circle {
  height: 100px;
  width: 100px;
  border-radius: 100px;
  border: 1px solid #f3f3f3;
  align-self: center;
  margin-top: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: scroll-circle 2s 5s infinite;
}

.scroll-circle svg {
  height: 30px;
}

.landing-bottom {
  border-left: 1px solid #ffffff;
  width: 100%;
  height: 250px;
  margin-left: 150px;
  margin-top: 90px;
  display: flex;
  justify-content: space-between;
}
.scroll-txt {
  display: flex;
  justify-content: flex-end;
  transform: rotate(-90deg);
  margin-left: 20px;
  margin-top: 160px;
  letter-spacing: 7px;
  font-weight: 200;
  font-size: 12px;
}
.right-bottom {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-right: 280px;
  width: 25%;
}
.right-bottom-special {
  font-size: 10px;
  margin-bottom: 15px;
  font-weight: 300;
  letter-spacing: 5px;
}
.right-bottom-txt {
  width: 70%;
  font-weight: 100;
}

@keyframes scroll-circle {
  0% {
    height: 100px;
    width: 100px;
  }
  50% {
    height: 90px;
    width: 90px;
  }
  100% {
    height: 100px;
    width: 100px;
  }
}

@media only screen and (max-width: 1500px) {
  .main-title {
    font-size: 96px;
  }
  .main-text-wrapper {
    margin-left: 150px;
  }
}

@media only screen and (max-width: 1250px) {
  .main-title {
    font-size: 84px;
  }
  .main-text-wrapper {
    margin-left: 90px;
  }
  .right-bottom-txt {
    width: 90%;
    font-size: 14px;
  }
}
@media only screen and (max-height: 900px) {
  .main-title {
    font-size: 64px;
    line-height: 84px;
  }

  .main-text-wrapper {
    margin-top: 120px;
  }
  .scroll-circle {
    margin-top: 60px;
  }
  .landing-bottom {
    margin-left: 90px;
    height: 200px;
  }
  .scroll-txt {
    margin-left: 30px;
    margin-top: 100px;
  }
}

@media only screen and (max-width: 1100px) {
  .main-title {
    font-size: 74px;
    content: "Proving why design matters.";
    text-align: center;
    line-height: 90px;
  }
  .main-text-wrapper {
    margin-left: 0px;
    justify-content: center;
    align-items: center;
  }
  .contact-btn {
    text-align: center;
  }
  .landing-bottom {
    border: none;
    justify-content: center;
    margin-left: 0px;
  }
  .scroll-txt {
    display: none;
  }
  .right-bottom {
    align-items: center;
    justify-content: center;
    width: 40%;
    margin-right: 0px;
  }
  .right-bottom-txt {
    width: 100%;
    text-align: center;
  }
}

@media only screen and (max-width: 900px) {
  .main-title {
    font-size: 54px;
    text-align: center;
    line-height: 70px;
  }
}

@media only screen and (max-width: 650px) {
  .main-title {
    font-size: 40px;
    text-align: center;
    line-height: 50px;
  }
  .top-text {
    text-align: center;
    font-size: 11px;
    padding-bottom: 20px;
  }
  .main-text-wrapper {
    margin-top: 200px;
  }
}

@media only screen and (max-width: 520px) {
  .main-title {
    font-size: 32px;
    text-align: center;
    line-height: 45px;
  }
  .right-bottom {
    width: 60%;
  }
}
