* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
body::-webkit-scrollbar {
  display: none;
}

body {
  scrollbar-width: none;
}

.App {
  overflow-x: hidden;
}
.footer {
  height: 18vh;
  display: flex;
  padding-left: 120px;
  padding-right: 250px;
  align-items: center;
  background-color: #0f0f0f;
  color: #fff;
  border-top: 1px solid #0f0f0f65;
}
.location {
  flex: 1;
}
.location-txt {
  font-size: 12px;
  padding-top: 10px;
  letter-spacing: 2px;
}
.footer-txt {
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 1px;
}
.contact {
  bottom: 0;
  width: 100%;
  z-index: -100;
}
.blank-section {
  height: 85vh;
  background-color: transparent;
  position: relative;
  z-index: -100;
}
@media only screen and (max-width: 1100px) {
  .footer {
    padding-left: 60px;
    padding-right: 190px;
  }
}

@media only screen and (max-width: 760px) {
  .footer {
    padding-left: 30px;
    padding-right: 170px;
  }
}

@media only screen and (max-width: 640px) {
  .footer {
    flex-direction: column;
    padding: 20px !important;
    text-align: center;
  }
  .location-txt {
    font-size: 9px;
  }
  .f-num {
    font-size: 10px !important;
    padding-top: 20px;
  }
  .footer-txt {
    font-size: 10px;
    font-weight: 300;
  }
}
